
import Vue from "vue";

export default Vue.extend({
  name: "SignUpInvitationCodeRequest",
  components: {},
  data() {
    return {
      data: {
        email: "",
        message: "",
        instagram: "",
        facebook: "",
        linkedin: "",
        twitter: ""
      },
      errors: {},
      btnLoading: false
    };
  },
  methods: {
    onSubmit() {
      this.btnLoading = true;

      this.$store
        .dispatch("mentor_application/POST_MENTOR_APPLICATION", this.data)
        .then(() => {
          this.btnLoading = false;
          // @ts-ignore
          this.$alertify.success(
            (this as any).$t("setting.receivedYourRequestMessage")
          );
          this.$router.push({
            name: "SignUpPageMentor"
          });
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
